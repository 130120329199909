import { useEffect, useState } from 'react'
import type { Collection } from 'src/components/department/types/collection-shelf'
import axios from 'axios'
import ProductShelfSkeleton from 'src/components/skeletons/ProductShelfSkeleton'
import type { CountdownTheme } from 'src/components/common/Countdown/Countdown'

import { validateProps, COLLECTION_API } from '../shelvesUtils'
import type { Product } from '../../types/product'
import ShelfV2 from '../ShelfV2/ShelfV2'

export type CollectionShelfv2Props = {
  enableSession: boolean
  addCountdown?: boolean
  countdownStyle?: string
  title: string
  collections: Collection[]
  type?: string
  shelfServerData?: Product[]
}

type CollectionResponse = {
  products: Product[]
  text: string
  id: string
  expireDate: string
}

type ShelfProps = {
  addCountdown: boolean
  collectionsProducts: CollectionResponse[]
  setActiveCollection: React.Dispatch<React.SetStateAction<string>>
  theme: CountdownTheme
}

const fetchCollectionProducts = async (collections: Collection[]) => {
  const requests = collections.map((collection) =>
    axios.get(
      `${COLLECTION_API}?collection=${collection.id}&engine=${collection.engine}&resultsPerPage=16&source=website`
    )
  )

  return Promise.all(requests)
}

const mapCollectionsResponse = (
  responses: any[],
  collections: Collection[]
): CollectionResponse[] => {
  return responses.reduce((acc, cur) => {
    const curCollection = collections.find(
      (collection) => collection.id === String(cur.data.collection.id)
    )

    if (curCollection) {
      acc.push({
        products: cur.data.products,
        text: curCollection.text,
        id: String(cur.data.collection.id),
        expireDate: cur.data.collection.dateTo,
      })
    }

    return acc
  }, [] as CollectionResponse[])
}

function getShelfProps(props: ShelfProps) {
  const { addCountdown, collectionsProducts, setActiveCollection, theme } =
    props

  return {
    tabs: !addCountdown
      ? collectionsProducts.map(({ text, id }) => ({ text, id }))
      : undefined,
    onTabClick: !addCountdown
      ? (id: any) => setActiveCollection(id)
      : undefined,
    expireDate: addCountdown ? collectionsProducts[0]?.expireDate : undefined,
    countdownId: addCountdown
      ? `shelfCountdown-${collectionsProducts[0]?.id}`
      : undefined,
    theme: addCountdown ? theme : undefined,
  }
}

const CollectionShelfv2 = ({
  collections,
  enableSession,
  addCountdown = false,
  countdownStyle = 'Vitrine Black Friday',
  title,
  type,
  shelfServerData,
}: CollectionShelfv2Props) => {
  const dataIsValid = validateProps({ collections, enableSession, title })
  const [activeCollection, setActiveCollection] = useState(collections[0].id)
  const [isLoading, setIsloading] = useState(false)
  const [activeCollectionProducts, setActiveCollectionProducts] = useState<
    Product[]
  >(shelfServerData ?? [])

  const [collectionsProducts, setCollectionsProducts] = useState<
    CollectionResponse[]
  >([])

  useEffect(() => {
    if (!dataIsValid || !enableSession) {
      return
    }

    async function getCollectionsProducts() {
      try {
        setIsloading(true)

        const responses = await fetchCollectionProducts(collections)

        const collectionsMap = mapCollectionsResponse(responses, collections)

        setCollectionsProducts(collectionsMap)
        setIsloading(false)
      } catch (e) {
        console.error(e)
      } finally {
        setIsloading(false)
      }
    }

    getCollectionsProducts()
  }, [collections, dataIsValid, enableSession])

  useEffect(() => {
    if (!dataIsValid || !enableSession) {
      return
    }

    const newProducts =
      collectionsProducts?.find(
        (collection) => collection.id === activeCollection
      )?.products ?? []

    setActiveCollectionProducts([...newProducts])
  }, [collectionsProducts, activeCollection, dataIsValid, enableSession])

  if (!dataIsValid || !enableSession) {
    return null
  }

  const theme = countdownStyle === 'Vitrine Black Friday' ? 'light' : 'dark'

  const {
    tabs,
    onTabClick,
    expireDate,
    countdownId,
    theme: shelfTheme,
  } = getShelfProps({
    addCountdown,
    collectionsProducts,
    setActiveCollection,
    theme,
  })

  return isLoading ? (
    <div className="flex justify-center items-center">
      <ProductShelfSkeleton />
    </div>
  ) : (
    <ShelfV2
      tabs={tabs}
      onTabClick={onTabClick}
      expireDate={expireDate}
      addCountdown={addCountdown}
      theme={shelfTheme}
      countdownId={countdownId}
      title={title}
      products={activeCollectionProducts}
      activeCollection={activeCollection}
      engine={`${
        collections.find((coll) => coll.id === activeCollection)?.engine
      }-editorial`}
      data-testid="collection-shelf"
      role="region"
      aria-label="sessão vitrines de coleção de produtos"
      className="restructure-tablet:my-[56px] restructure-small-desktop:my-[80px] container-vitrine-queridinhos flex justify-center flex-col"
      type={type}
    />
  )
}

export default CollectionShelfv2
